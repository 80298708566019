<td class="colstat colstat-medium">
<!--  <app-gen-input title_ph_lang="patient" [actualObj]="row" (click)="openPatient(row)"  fieldName="personName" />-->
  <div titleLang="patient" [class.patient]="!!row.patient_id">
    <app-patient-modal [id]="row.patient_id">
      {{row.personName}}
    </app-patient-modal>
  </div>
</td>



<td>
  <app-yes-no (onClick)="updField('arrived')" [isCheck]="row.arrived=='yes'"   ></app-yes-no>
</td>
<td>
  <app-wysiwyg #descriptionWysiwyg [actualObj]="row" fieldName="remarks" [tabindex]="-1"
               title_ph_lang="medical_record" (changeEvent)="updField('remarks')" isMedicalRecord
               showTestsBtn (openTests)="openTests(row) "showAutotextsBtn (openAutotexts)="openAutoTextMenu(row)"

               [initHeight]="eventsService.isMobile ? eventsService.MobileHeight - 630 : null"  />
<!--  showAutotextsBtn (openAutotexts)="openAutoTextMenu()"-->
<!--        <app-gen-input type="textarea"  [actualObj]="row" fieldName="remarks" (changeEvent)="updField('remarks')" />-->
</td>
@if(isManualChargeShown=='yes' && !row.invoice_id_patient){
  <td class=" colstat colstat-big">
    <app-gen-button-group [actualObj]="row" fieldName="charged_manualy" (changeEvent)="updField('charged_manualy')" optionsListName="charged_manualy_no_opts"/>
  </td>
  <td class="colstat colstat-small">
    @if(row.charged_manualy=='yes'){
      <div class='input-group'>
         <app-gen-input title_ph_lang="external_invoice_id" [actualObj]="row" (changeEvent)="updField('external_invoice_id')"  className="border-0" fieldName="external_invoice_id" />
      </div>
    }
  </td>
}
