<div >

  @if(isLocked){
    <div class="row">
      <div class="col-12">
        {{"record_is_locked" | langVal}}
      </div>
    </div>
  }

  <div class="row form-group">
    <div class="colstat colstat-small">
      <label>{{"number" | langVal}}</label>
      <app-gen-input  [actualObj]="patient" disabled [errors]="errors" fieldName="id"
        title_ph_lang="number" >
      </app-gen-input>
    </div>
    <div class="colstat colstat-big">
      <label>{{"patient_name" | langVal}}</label>

      <app-gen-input [tabindex]="-1" autocomplete="off"  [disabled]="isLocked"
        [actualObj]="patient" [errors]="errors" fieldName="name" title_ph_lang="patient_name"
         (keyupEvent)="nameChangeConfirmHappened? validateAndSave() : nameSubj.next(true)">
      </app-gen-input>
    </div>

    @if(insert_patient_template.id_number){
      <div class="colstat colstat-small">
        <label>{{"id_number" | langVal}}</label>
        <app-gen-input [tabindex]="3"  [actualObj]="patient" [errors]="errors" [disabled]="isLocked"
          fieldName="id_number"
          (keyupEvent)="validateAndSave()">
        </app-gen-input>
      </div>
    }
    @if(insert_patient_template.gender){
      <div class="colstat colstat-medium">
        <label>{{"gender" | langVal}}</label>
        <app-gen-button-group [actualObj]="patient" fieldName="gender" optionsListName="gender_opts" [disabled]="isLocked"
                              (changeEvent)="validateAndSave()"></app-gen-button-group>

      </div>
    }

    <div class="colstat colstat-small pad-btns">
      <app-gen-button-group [actualObj]="patient" fieldName="status" optionsListName="patient_or_inquirer_opts"  [disabled]="isLocked"
        (changeEvent)="validateAndSave()"></app-gen-button-group>


    </div>
  </div>
  <div class="row form-group">
    @if(insert_patient_template.payor_id && permsService?.perms?.administrative){
      <div class="colstat colstat-big">
        <label>{{"payor" | langVal}}</label>
        <app-gen-autocomplete #payorFuzzyInput title_ph_lang="payor" [actualObj]="patient" fieldName="payor_id"
                              [disabled]="isLocked" [tabindex]="-1" [fuzzySearchableClasses]="['payors']"
                              [error]="errors.payor_id" (chosenEvent)="validateAndSave()"
                              [hasPlus]="permsService?.perms?.administrative"
                              plusLang="add_payor" (plusClick)="openAddPayorModal()"></app-gen-autocomplete>
      </div>
    }
  </div>
  @if(patientfields?.length){
    <div class="row form-group">
      @for(patientfield of patientfields;track patientfield){
        <div class="col-12 col-md-5 offset-md-1">
          <label>{{patientfield.name | langVal}}</label>
          <app-ta-expands #taExpands [actualObj]="patient" [errors]="errors" [disabled]="isLocked"
                          [fieldName]="'patientfield_id_'+patientfield.id"
                          [title_ph_lang]="patientfield.name" (keyupEvent)="validateAndSave()">
          </app-ta-expands>
        </div>
      }
    </div>
  }
  <hr>
  @if(permsService?.perms?.administrative && permsService.owner_has_users){
    <div class="row form-group">
     <div class="col-12 colstat colstat-big" >
       <label>{{"filter_sub_users" | langVal}}</label>
       <app-autocomplete-with-tags (chosenEvent)="updateExcludedIds($event)" title_ph_lang="filter_sub_users" />
      </div>
    </div>
    <div class="row form-group">
     <div class="col-12 colstat colstat-big" >
       <label>{{"assign_sub_users" | langVal}}</label>
        <app-autocomplete-multi [ids]="patient?.assoc || []" (addItem)="assocAddItem($event)" title_ph_lang="assign_sub_users" [isStatic]="isLocked" siteDataTable="sub_users" [siteDataFilters]="{active:'yes'}"
              (deleteItem)="assocDeleteItem($event)" [excludeIds]="excludeIds" />
      </div>
    </div>
  }


  <div class="row form-group">
    @if(insert_patient_template.year_of_birth){
      <div class="col-4 col-md-2">
        <label>{{"year_of_birth" | langVal}}</label>
        <app-gen-input [tabindex]="4"  [actualObj]="patient" [errors]="errors" [disabled]="isLocked || patient.date_of_birth"
          fieldName="year_of_birth" (keyupEvent)="validateAndSave()">
        </app-gen-input>

      </div>
    }
    @if(insert_patient_template.date_of_birth){
      <div class="col-4 col-md-2">
        <label>{{"date_of_birth" | langVal}}</label>
        <app-date-picker  [actualObj]="patient" [errors]="errors" fieldName="date_of_birth" [disabled]="isLocked" startFromDecade
          isFullYear [tabindex]="-1" (changeEvent)="validateAndSave()" [addonObject]="{after:{icon:'calendar'}}" />

      </div>
    }
    @if(patient.year_of_birth || patient.date_of_birth){
      <div class="colstat colstat-small">
        <label>{{"age" | langVal}}</label>
        <app-gen-input [actualObj]="patient" fieldName="age" disabled />
      </div>
    }
    @if(insert_patient_template.number_of_kids){
      <div class="colstat colstat-small">
        <label>{{"number_of_kids" | langVal}}</label>
        <app-gen-input [tabindex]="6"  [actualObj]="patient" [errors]="errors" [disabled]="isLocked"
          fieldName="number_of_kids"
          (keyupEvent)="validateAndSave()">
        </app-gen-input>

      </div>
    }
  </div>

  <div class="row form-group" >
    @if(insert_patient_template.marital_status){
      <div class="colstat colstat-small-triple pad-btns">
        <app-gen-button-group [actualObj]="patient" fieldName="marital_status" optionsListName="marital_status_opts" [disabled]="isLocked" (changeEvent)="validateAndSave()" />

      </div>
    }

    @if(insert_patient_template.parents_marital_status){
      <div class="colstat colstat-small-triple pad-btns">
        <app-gen-button-group [actualObj]="patient" fieldName="parents_marital_status" optionsListName="parents_marital_status_opts" [disabled]="isLocked" (changeEvent)="validateAndSave()" />

      </div>
    }

  </div>

  <div class="row form-group">

    @if(insert_patient_template.name_en){
      <div class="colstat colstat-big">
        <label>{{"name_en" | langVal}}</label>
        <app-gen-input [tabindex]="-1" className="text-left" [actualObj]="patient" [disabled]="isLocked"
                       [errors]="errors" fieldName="name_en"
                       (keyupEvent)="validateAndSave()">
        </app-gen-input>
      </div>
    }

    @if(insert_patient_template.address){
      <div class="colstat colstat-big">
        <label>{{"address" | langVal}}</label>
        <app-gen-input [tabindex]="-1" className="text-left" [actualObj]="patient" [disabled]="isLocked"
                       [errors]="errors" fieldName="address"
                       (keyupEvent)="validateAndSave()">
        </app-gen-input>
      </div>
    }


  </div>
  <div class="row form-group">
    @if(insert_patient_template.notifiy){
      <div class="colstat colstat-small">
        <label>{{"notifiy" | langVal}}</label>
        <div>
          <app-gen-button-group [actualObj]="patient" fieldName="notifiy"  optionsListName="yes_no_opts" [disabled]="isLocked"
            (changeEvent)="validateAndSave()"></app-gen-button-group>

        </div>
      </div>
    }
    @if(insert_patient_template.notification_default){
      <div class="colstat colstat-small">
        <label>{{"notification_default" | langVal}}</label>
        <div>
          <app-gen-button-group [actualObj]="patient" fieldName="notification_default" optionsListName="notification_defaultOpts" [disabled]="isLocked" (changeEvent)="validateAndSave()" />
        </div>
      </div>
    }

    @if(insert_patient_template.automatic_proforma){
      <div class="colstat colstat-big pad-btns">
        <app-gen-button-group [actualObj]="patient" fieldName="automatic_proforma" optionsListName="automatic_proforma_opts" [disabled]="isLocked" (changeEvent)="validateAndSave()" />
      </div>
    }

  </div>
  @if(insert_patient_template.referer){
    <div class="row form-group">
      <div class="col-12">
        @if(permsService?.perms?.administrative){
          <button titleLang="referers" tabindex="-1" class="btn btn-primary" (click)="openReferer()">{{"referers" | langVal}}</button>
          @if(permsService.owner_has_users){
            <button titleLang="apotropuses" tabindex="-1" class="btn btn-primary" (click)="openApotropus()">{{"apotropuses" | langVal}}</button>
          }
        }
      </div>
    </div>
  }
  <div class="row">  <!--this was made for  user 2149 consider if to keep-->
    <div class="colstat colstat-big">
    <label>{{"date_start_therapy" | langVal}}</label>
    <app-date-picker  [actualObj]="patient" [errors]="errors" fieldName="date_start_therapy" [disabled]="isLocked"
                     isFullYear  [tabindex]="-1"
                     (changeEvent)="validateAndSave()" [addonObject]="{after:{icon:'calendar'}}">
    </app-date-picker>
    </div>
    <div class="colstat colstat-small">
      <label>{{"created_at" | langVal}}</label>
      <div>
        {{patient.created_at | dateFormat}}
      </div>
    </div>
    <div class="colstat colstat-big pad-btns" >
      <app-gen-button-group [actualObj]="patient" fieldName="active" optionsListName="patient_active_insert_opts" [disabled]="isLocked" (changeEvent)="validateAndSave()"></app-gen-button-group>
    </div>

  </div>
  @if(insert_patient_template.remarks){
    <div class="row form-group">
      <div class="col-12">
        <label>{{"remarks" | langVal}}</label>
        <app-wysiwyg #remarksWysiwyg [actualObj]="patient" fieldName="remarks" [tabindex]="-1" [isStatic]="isLocked"
            (keyupEvent)="validateAndSave()" />
      </div>
    </div>
  }
  @if(insert_patient_template.tags){
    <div class="row form-group">
      <div class="col-12">
        <label>{{"tags" | langVal}}</label>
        <app-autocomplete-with-tags (chosenEvent)="chooseTags($event)"  title_ph_lang="tags" [objs]="tags"  [isStatic]="isLocked" hasPlus />
      </div>
    </div>
  }
  <div class="row form-group">
    @if(insert_patient_template.is_withholding_tax){
      <div class="colstat colstat-big pad-btns">
        <app-gen-button-group [actualObj]="patient" fieldName="is_withholding_tax" optionsListName="is_withholding_tax_opts" [disabled]="isLocked" (changeEvent)="validateAndSave()" />
      </div>
    }
    @if(insert_patient_template.withholding_tax_percent){
      <div class="colstat colstat-small">
        <label>{{"withholding_tax_percent" | langVal}}</label>
        <app-gen-input [tabindex]="-1"  [actualObj]="patient" [errors]="errors" [disabled]="isLocked"
                       fieldName="withholding_tax_percent" (keyupEvent)="validateAndSave()" />
      </div>
    }
  </div>



  <div class="row form-group">

    <div class="bottom-row-buttons mb-2">
      <div class="action-buttons p-0 d-flex justify-content-sm-end justify-content-between">
      @if(permsService.perms?.administrative){
        <button titleLang="invoice_short" tabindex="-1" class="btn btn-primary" (click)="addInvoiceEv.emit()" preIcon="money-white" isImg >{{"invoice_short" | langVal}}</button>
        @if(permsService.owner_has_users){
          <button titleLang="assoc" tabindex="-1" class="btn btn-primary" (click)="openAssoc()" preIcon="user" >{{"assoc" | langVal}}</button>
        }
      }
      @if((permsService?.perms?.administrative || permsService?.perms?.clinical ) && permsService.owner_has_users){
        <button titleLang="tasks" tabindex="-1" class="btn btn-primary" (click)="openTasks()" preIcon="task">{{"tasks" | langVal}}</button>
      }


      <button titleLang="letter" tabindex="-1" class="btn btn-primary"
        (click)="addDocumentEv.emit()" preIcon="document-white" isImg >{{"letter" | langVal}}
      </button>

      <button titleLang="upload_file" tabindex="-1" class="btn btn-primary"
        (click)="openUploadDocumentModalEv.emit()" preIcon="document-white" isImg >{{"upload_file" | langVal}}
      </button>
        @if(!patient?.filled_stage || patient?.filled_stage=="invited"){
          @if(patient?.contactways?.length){
            <button titleLang="send_invite_to_fill" tabindex="-1" class="btn btn-primary" (click)="store.patientSendInvite(patient.id)">{{"send_invite_to_fill" | langVal}}</button>
          }
        }
        @if(patient?.filled_stage=="received"){
          <app-btn-ext text="update_from_patient_form" [isSpin]="isApprovingPatientDetails" (clickEv)="isApprovingPatientDetails=true;store.sendFillDetailsApprove(patient.id)" />
        }
     <!--  toKeep @if(permsService.perms?.administrative){
      <button titleLang="upload_file" tabindex="-1" class="btn btn-primary" (click)="sendInvite()">{{"send_invite" | langVal}}</button>
      } -->
      </div>
    </div>
  </div>
</div>
