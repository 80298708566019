import { EventsService } from '@app/services/events.service';
import { WysiwygComponent } from '../utils/wysiwyg/wysiwyg.component';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, SimpleChange } from '@angular/core';
import { Configcliniq, ConfigDefinitions, Fp_image, StoreFollowup, UpdateGroup, yes_no_opts } from '@app/definitions/types';
import { Followup, Patient } from '@app/definitions/types';
import * as moment from 'moment';
import { GenFileInputComponent } from '@app/gen-inputs/gen-file-input/gen-file-input.component';
import { Modalable } from '@app/Modalable';
import { YesNo } from '@app/utils/utils.component';

@Component({
	selector: 'app-home-meeting',
	templateUrl: './home-meeting.component.html',
	styleUrl: './home-meeting.component.css'
})
export class HomeMeetingComponent extends Modalable {
  modalTitleLang: string="edit_followup";
  hasExit: boolean=true;

	//addDocument,addInvoice,openUploadDocumentModal
	@Output() addDocumentEv = new EventEmitter();
	@Output() addInvoiceEv = new EventEmitter();
	@Output() openUploadDocumentModalEv = new EventEmitter();

	@Input() followup: Followup;
	@Input() patient: Patient;
	@Input() user: any;
	@Input() noActionBtns: boolean = false;
	@ViewChild("descriptionWysiwyg") descriptionWysiwyg: WysiwygComponent;
	@ViewChild("remarksWysiwyg") remarksWysiwyg: WysiwygComponent;
	@ViewChild("imageUploadInput") imageUploadInput: GenFileInputComponent;
	@Output() moveToOtherFollowup = new EventEmitter();
	@Output() invoiceCreated = new EventEmitter();
	errors: any = {};	//will hold errors for the fields in the form
	isFormSubmittable: boolean = false;	//can submit (controls disabled in the submit button)
	curFocus: string = "description";
	isSaving: boolean = false;	//while waiting for the api to return from saving (controls save btn disabled and outputs a "waiting" gif)

	isManualChargeShown: string = 'no';
  isPayorShown: yes_no_opts = "no";
  isPMShown: boolean = false;
  EnablePastFollowupsOnFollowup: boolean = false;
  EnableGroupAttendsOnFollowup: boolean = false;
  ShowVersions: string = 'no';
  ShowPersonalMemorandum: string = 'no';
	hasNonAttend: boolean = false;
	newImageObj: any = null;
	isUploading: boolean = false;
	goToOtherFollowup: boolean = false;
	patientImages: Fp_image[] = [];
	isFetchingPatientImages: boolean = false;
  isMobile: boolean = false;
	invoice_per_followup: boolean = false;

	validationFields: any = {	//field validations, control displaying field errors and ability to submit the form
		// name:{'not_empty':null},
    price:{not_empty: true, is_decimal_or_zero: true},
    price_payor:{not_empty: true, is_decimal_or_zero: true},
	};
	message: string = '';
	sub_user_names: string = "";
  hasPayors:number=0;

	ngOnInit(): void {
		let cliniqData = this.store.getCliniqDataOrExit();
		this.ShowVersions = cliniqData.show_versions;
		this.ShowPersonalMemorandum = cliniqData.show_personal_memorandum;
		this.isManualChargeShown = cliniqData.show_manual_charge;
		this.isPayorShown = "no";
		this.isPMShown = false;
		this.EnablePastFollowupsOnFollowup = (cliniqData.enable_past_followups_on_followup == "yes");
		this.EnableGroupAttendsOnFollowup = (cliniqData.enable_group_attends_on_followup == "yes");
		this.calcPrices();
		this.invoice_per_followup = (cliniqData.invoice_per_followup == "yes");
    this.isMobile = this.eventsService.isMobile;
		this.resetWysiwygs();
    this.hasPayors=this.store.getSiteDataTable("payors").length;

    this.followupOnChange();

  }
	ngOnDestroy() {
		if (this.followup) {
			this.saveFollowup(this.followup);
		}
	}
  ngOnChanges(changes: SimpleChange) {
    this.isPayorShown = "no";
    this.isPMShown = false;
		if (changes['followup']) {
			if (changes['followup']?.previousValue?.id) {
				this.saveFollowup(changes['followup']?.previousValue);
			}
      this.followupOnChange();
		}
		this.eventsService.scrollToTop();
	}
  async loadFollowupImages(){
    const res:any=await this.apiService.post("get_fp_images",{ followup_id: this.followup.id });
    this.followup.images = res.items.map(item => this.store.createUrlForImage(item));
  }
  async loadFollowupHasNonAttend(){
    const res:any=await this.apiService.post("followup_has_non_attend",{followup_id:this.followup.id});
    this.hasNonAttend = !!res;
  }
  async followupOnChange(){

    // this.patient={...this.patient};
    // this.followup={...this.followup};
    if (this.followup) {
      this.isUploading = false;
      this.goToOtherFollowup = false;
      this.followup.updated_at_display = this.servTimeToLocalTimeDisplay(this.followup.updated_at);

      this.loadFollowupImages();

      if (!this.followup.meetingtype_name) {
        const meetingTypes = this.store.getSiteDataTable("meetingtypes");
        const meetingType = meetingTypes.find(it => it.id == this.followup.meetingtype_id);
        if (meetingType) {
          this.followup.meetingtype_name = meetingType.name;
        }
        if(this.followup.meetingtype_id==-1){
          this.followup.meetingtype_name = this.lang.getVal("former_debt");
        }
        if (this.patient?.is_group == 'yes') {
          this.hasNonAttend = false;
          this.loadFollowupHasNonAttend();

        }
      }
      this.followup.time = moment.utc(this.followup.date + " " + this.followup.time).format("HH:mm");
      this.resetWysiwygs();

      this.sub_user_names = "";
      const fus: any = this.store.getFusById(this.followup.series_id);
      if (fus) {
        this.sub_user_names = this.store.getAssocSubUsersForFus(fus).map(it => it.name).join(", ");
      }


    }
    this.calcPrices();
  }

  async resetWysiwygs(){

    await this.store.timeout(500);
    if (this.descriptionWysiwyg) {
      this.descriptionWysiwyg.reset(true);
    }
    if (this.remarksWysiwyg) {
      this.remarksWysiwyg.reset();
    }
  }

	async deleteImage(imageObj: Fp_image) {
    if(imageObj.isDeleting){return;}
		imageObj.isDeleting = true;
		const res:any=await this.apiService.post("delete_fp_image",imageObj,"id");
    if (res.success) {
      this.followup.images = this.followup.images.filter(it => it.id != imageObj.id);
      this.modalService.openToast(this.lang.getVal("deleted"));
    }
	}
	openImage(ind: number) {
		this.modalService.openMulti("image-gallery", {
      images: this.followup.images.map(img => ({ ...img, date: this.followup.date })),
      curImagePosition: ind
		})
	}

	async uploadImage(files: FileList = null, otherFile: any = null) {
		if (!this.patient) { return; }
		const file = (files ? files.item(0) : (otherFile || ""));
		if (file) {
			this.isUploading = true;
      const res:any=await this.apiService.upload("create_fp_image",{ followup_id: this.followup.id, patient_id: this.patient.id,newValue: file });
      this.isUploading = false;
      if (res.item) {
        this.modalService.openToast(this.lang.getVal("upload_successful"));
        this.followup.images = [...this.followup.images, this.store.createUrlForImage(res.item)];
        this.imageUploadInput.empty();
      }
      else {
        this.modalService.openToast(this.lang.getVal("an_error_occured"));

      }
		}
	}

	validateField() {
		this.isFormSubmittable = this.validator.validateEntireForm(this.followup, this.errors, this.validationFields);
	}

	async openPayorPricesModal() {
		const payor = this.store.getSiteDataTable('payors').find(it => it.id == this.followup.payor_id);

		await this.modalService.openMulti("gen-table-display", {
      tableName: 'payorprices',
      filterField: 'payor_id',
      objectRow: payor
		});
		this.save();
	}

	calcPrices() {
		this.followup.price_patient = this.followup.price - this.followup.price_payor;
	}




	async openAutoTextMenu() {
		if (!this.patient) { return; }
		const text: any = await this.modalService.openMulti("autotext-menu", {patient_id: this.patient.id });
		this.insertText(text);
	}
	async openTests() {
		if (!this.patient) { return; }
		const text: any = await this.modalService.openMulti("tests-menu", {
      patient_id: this.patient.id,
      in_followup: true,
    });
		this.insertText(text);
	}

	insertText(text: string = "") {
		if (text) {
			this[this.curFocus == "description" ? "descriptionWysiwyg" : "remarksWysiwyg"].injectText(text);
		}
	}
	onFocus(fieldName: string) {
		this.curFocus = fieldName;
	}

	openGroupAttendModal() {
		if (!this.patient) { return; }
		this.modalService.openMulti("group-attends",
			{
        group_id: this.patient.id, 
        followup_id: this.followup.id,
        hasNonAttendFunc: (newValue: boolean) => {
          this.hasNonAttend = newValue;
        }
			});
	}

	servTimeToLocalTimeDisplay(t) {

    const mom=moment.utc(t).local();
    const momToday=moment.utc().local();

    if(mom.format(ConfigDefinitions.momentDateDisplay) == momToday.format(ConfigDefinitions.momentDateDisplay)){
      return this.lang.getVal("today")+" " + this.lang.getVal("at")+" "+mom.format("HH:mm");
    }else{
      return mom.format(ConfigDefinitions.momentDateDisplay)+" "+this.lang.getVal("at")+" "+mom.format("HH:mm");
    }
	}

	async saveFollowup(followup: Followup) {
    if (followup.payor_id == 0) {
      followup.price_payor = 0;
    }
    const res=await this.store.genSaveFollowup(followup);
    followup.updated_at_display = this.servTimeToLocalTimeDisplay(followup.updated_at);
    return res;
	}


	async save(goToOtherFollowup: boolean = false, isNext: boolean = true) {	//isNext=false is "go to previous instead"


    if(!Number(this.followup.payor_id)){
      this.followup.charged_manualy_payor="no";
    }

		if (!this.goToOtherFollowup) {	//if goToOtherFollowup was already set as true - cannot be changed
			this.goToOtherFollowup = goToOtherFollowup;
		}
		if (this.isSaving) { return }	//is curently waiting for api to finish saving - exit
		this.isSaving = true;	//flag "waiting for the api to finish saving" as true

		const savedFollowup = this.followup;
		const success = await this.saveFollowup(savedFollowup);
		this.isSaving = false;
		if (success) {
			//absorb price/price_payor to fp and store
			this.calcPrices();
			if (this.goToOtherFollowup) {
				this.moveToOtherFollowup.emit(isNext);
				this.goToOtherFollowup = false;
				//  this.modalService.openToast(this.lang.getVal("updated_successfully"));
			}
		}
	}

	openGroup() {
    this.modalService.openMulti("update-group",{  group: this.patient  });
	}

	async getPatientImages() {
		if (this.isFetchingPatientImages) { return }
		this.isFetchingPatientImages = true;
		const res:any=await this.apiService.post("get_fp_images",{ patient_id: this.patient.id });
    this.isFetchingPatientImages = false;
    this.patientImages = res.items.map(item => this.store.createUrlForImage(item));
    this.modalService.openMulti("image-gallery", {
      images: this.patientImages
    })
	}
	postChargedChange(){
		if(this.followup.charged=="yes"){
			const fus=this.store.data.followupseries.find(f=>f.id==this.followup.series_id);
			if(fus){
				this.followup.price=fus.price;
			}
		}
		this.save();
	}
  openMedilog(){
    this.modalService.openMulti("gen-table-display",{
      tableName: 'medilogs', filterField: "resource",
      filterFieldValue: "followup",
      filterFieldApiObject: { resource_id:this.followup.id }
		});
  }

}
