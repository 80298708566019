<div class="row">
  <div class="colstat colstat-big">
    <label>{{"group_name" | langVal}}</label>
    <app-gen-input tabindex="1" autocomplete="off" autofocus="autofocus" [actualObj]="group" [errors]="errors" fieldName="name" (keyupEvent)="validateField('name')" (changeEvent)="onChange('name')" />
  </div>
  <div class="colstat colstat-big">
    <label>{{"payor" | langVal}}</label>
    <app-gen-autocomplete #payorFuzzyInput title_ph_lang="payor"
                          [tabindex]="-1" [fuzzySearchableClasses]="['payors']"
                          [actualObj]="group" fieldName="payor_id"
                          [error]="errors.payor_id"
                          (chosenEvent)="onChange('payor_id')"
                          hasPlus plusLang="add_payor"
                          (plusClick)="openAddPayorModal()"
    ></app-gen-autocomplete>
  </div>
</div>
<div class="row">
  <div class="colstat colstat-small">
    <label>{{"price_patient" | langVal}}</label>
    <app-gen-input [actualObj]="group" [errors]="errors" fieldName="patientprice"
      title_ph_lang="price_patient"  (keyupEvent)="validateField('patientprice')" (changeEvent)="onChange('patientprice')" />
  </div>
  @if(group.id){
    <div class="colstat colstat-small">
      <label>{{"active" | langVal}}</label>
      <app-gen-button-group [actualObj]="group" fieldName="active" optionsListName="yes_no_opts" (changeEvent)="validateField('active');onChange('active')" />
    </div>
  }
</div>
<div class="row">
  @if(permsService.owner_has_users && permsService.perms?.administrative){
    <div class="colstat colstat-big">
      <label>{{"first_assoc" | langVal}}</label>

      @if(group.id){
        <app-autocomplete-multi [ids]="group.assoc" (addItem)="assocAddItem($event)"
        (deleteItem)="assocDeleteItem($event)" [excludeIds]="excludeIds" siteDataTable="sub_users" [siteDataFilters]="{active:'yes'}" />
      }
      @else{
        <app-autocomplete-multi (chosenEvent)="newRowAddAssoc($event)" [excludeIds]="excludeIds" siteDataTable="sub_users" [siteDataFilters]="{active:'yes'}" />
      }
    </div>
  }
</div>
<div class="buttons-row  row">
  <div class="colstat colstat-big">
    <label class="button-label">{{"charge_mode" | langVal}}</label>
    <app-gen-button-group [actualObj]="group" fieldName="charge_mode" optionsListName="chargeModeOpts"
       (keyupEvent)="validateField('charge_mode')" (changeEvent)="onChange('charge_mode')">
    </app-gen-button-group>
  </div>
  <div class="colstat colstat-big">
    <label class="button-label">{{"payment_mode" | langVal}}</label>
    <app-gen-button-group [actualObj]="group" fieldName="payment_mode" optionsListName="paymentModeOpts"
    (keyupEvent)="validateField('payment_mode')" (changeEvent)="onChange('payment_mode')"></app-gen-button-group>
  </div>
</div>

@if(group.id){
  <app-gen-table tableName="grouppatients" filterField="group_id" [filterFieldValue]="group.id" />
}
@else{
  <div class="modal-btns">
    @if(isModalMode){
      <app-cancel-btn (click)="closeModal.emit()" />
    }
    <app-btn-ext [disabled]="!isFormSubmittable" (clickEv)="save()" [isSpin]="isSaving" text="add" />
  </div>
}

