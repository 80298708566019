<app-table-wrap [fieldsStr]="isManualChargeShown=='yes' ? 'name,arrived,remarks,charged_manualy,external_invoice_id' : 'name,arrived,remarks'"> <!--,charged_manualy,external_invoice_id-->
  @for(row of rows;track row){
    <tr app-group-attends-row
      [row]="row"
      [isManualChargeShown]="isManualChargeShown"
      (updated)="updated()"

    ></tr>
  }
</app-table-wrap>
