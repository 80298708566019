import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@app/services/api.service';
import { EventsService } from '@app/services/events.service';
import { LangService } from '@app/services/lang.service';
import { ModalService } from '@app/services/modal.service';
import { OverlapService } from '@app/services/overlap.service';
import { PermsService } from '@app/services/perms.service';
import { SearchSortService } from '@app/services/search-sort.service';
import { StoreService } from '@app/services/store.service';
import { ValidatorService } from '@app/services/validator.service';
import { WysiwygComponent } from '@app/utils/wysiwyg/wysiwyg.component';

@Component({
  selector: '[app-group-attends-row]',
  templateUrl: './group-attends-row.component.html',
  styleUrl: './group-attends-row.component.css'
})
export class GroupAttendsRowComponent {
  @Input() row:any;
  @Input() isManualChargeShown:string;
  @Output() updated = new EventEmitter();
  @ViewChild("descriptionWysiwyg") descriptionWysiwyg: WysiwygComponent;


  constructor(public lang: LangService, public apiService: ApiService, public modalService: ModalService,public eventsService: EventsService, public store: StoreService){}

  async updField(fieldName: string) {	//update attends row
		//let newVal = (fieldName == "arrived" ? (row.arrived == "yes" ? "no" : "yes") : row.remarks);	//val is either arrived (flip "yes"/"no") or remarks
		const newVal = fieldName == "arrived" ? ( this.row.arrived == "yes" ? "no" : "yes" ) : this.row[fieldName] ;
    this.row[fieldName] = newVal;	//update the field
		const res:any=await this.apiService.save_gen_field("groupattends", this.row.id, fieldName, newVal);
    if (!res.success) {	return}
    this.modalService.openToast(this.lang.getVal("update_successfuly"));
    this.updated.emit()

	}
  async openAutoTextMenu(row:any) {
		const text: any = await this.modalService.openMulti("autotext-menu", {patient_id: row.patient_id });
    if(text){
      this.descriptionWysiwyg.injectText(text);
    }
		// this.insertText(text);
	}
  async openTests(row:any) {
		const text: any = await this.modalService.openMulti("tests-menu", {patient_id: row.patient_id });
    if(text){
      this.descriptionWysiwyg.injectText(text);
    }
		// this.insertText(text);
	}

}
