import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, booleanAttribute} from "@angular/core";
import { StoreService } from "@app/services/store.service";
import { ApiService } from "@app/services/api.service";
import { LangService } from "@app/services/lang.service";
import { ModalService } from "@app/services/modal.service";
import { EventsService } from "@app/services/events.service";

@Component({
  templateUrl: './ac-common.component.html',
  styleUrl: './ac-common.component.css'
})
export class AcCommonComponent {
  @Input() noInput: boolean = false;
  @Input() error: string = "";
	@Input({transform:booleanAttribute}) isStatic: boolean = false;
	@Input() placeholder: string = "";
	@Input() title_ph_lang: string = "";
	@Input() title: string = "";
	@ViewChild("inp") inp: ElementRef;
	chosenObjs: any[] = [];
	@Output() chosenEvent = new EventEmitter();
	inputStr: string = "";
	options: any[] = [];
	@Input() excludeIds: any[] = [];
  @Input() isLoading: boolean = false;
	@Input() tabindex: string = "";
  @Input() hasPlus: boolean = false;
  docClickSubscription: any;

  constructor(public store: StoreService, public apiService: ApiService, public lang: LangService, public modalService: ModalService, public eventsService: EventsService) { }

  init(){
    this.eventsService.escapeKeySubj.subscribe(() => {
      this.emptyOpts();
    });
    this.docClickSubscription = this.eventsService.docClickSubject.subscribe((val: any) => {	//when document clicks - if the click source is not this popoup, close it
			if (val.src != this) {
				this.emptyOpts();
			}
		})
  }
  ngOnDestroy() {	//unsubscribe from the document clicks broadcast
		this.docClickSubscription.unsubscribe();
	}
  handleClick(ev:any){
    ev.stopImmediatePropagation();
    this.eventsService.onDocClick({ src: this });
    this.keyup(true);
  }

  emptyOpts() {
		this.options = [];
	}

  reset() {
		this.chosenObjs = [];
	}
  outputValue() {
		this.chosenEvent.emit([...this.chosenObjs]);
	}
  focus() {
		this.inp.nativeElement.focus();
	}
  optAlreadyInChosen(optObj:any):boolean{return true}
  keyupOtps(strToLower:string):any{}
  keyup(showOptsOnEmpty: boolean = false) {    
		this.options = [];
		if (!this.inputStr && !showOptsOnEmpty) {
			return;
		}
		const strToLower = this.inputStr.toLowerCase();

    const opts=this.keyupOtps(strToLower).filter(it=>!this.optAlreadyInChosen(it));

		this.excludeIds = this.excludeIds.map(it => String(it));
		this.options = opts.filter(it => !this.excludeIds.includes(String(it.id)));
	}
  delete(obj:any){}
  addOption(obj:any){}
  openAddModal(){}
}
