import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef, SimpleChange, booleanAttribute } from '@angular/core';
import { TagItemTypes } from '@app/definitions/types';
import { AcCommonComponent } from '../ac-common/ac-common.component';

@Component({
	selector: 'app-autocomplete-with-tags',
	templateUrl: '../ac-common/ac-common.component.html',
	styleUrls: ['../ac-common/ac-common.component.css','./autocomplete-with-tags.component.css']
})
export class AutocompleteWithTagsComponent extends AcCommonComponent{
  @Input({transform:booleanAttribute}) hasPlus: boolean = false;
	@Input() item_type: TagItemTypes = "patient";
	@Input() item_id: number = 0;
	@Input({transform:booleanAttribute}) saveMode: boolean = false;
	@Input({transform:booleanAttribute}) isLocationLimited: boolean = false;
	@Input() fuzzySearchableClasses: string[] = null;
	@Input() objs: any[] = [];



	ngOnChanges(changes: SimpleChange) {
		if (changes["objs"]) {
			this.chosenObjs = this.objs;
		}
	}

	ngOnInit() {
    this.init();
		if (!this.saveMode && !this.isStatic) {
			return;
		}

    if(this.item_id){
      const tagIds = this.store.getSiteDataTable("object_to_tags").filter(row => row.item_type == this.item_type && row.item_id == this.item_id).map(row => row.tag_id);
      if (tagIds?.length) {
        this.chosenObjs = this.store.getSiteDataTable("tags")
          .filter(it => tagIds.includes(it.id))
          .map(obj => this.getOpt(obj, "tags"));
      }
    }
	}


	async delete(optObj: any) {
		this.chosenObjs = this.chosenObjs.filter(ob => ob.id != optObj.id || ob.typeClassName != optObj.typeClassName);
		this.outputValue();
		this.focus();
		if (!this.saveMode) {
			return;
		}

		const objToDel = this.store.getSiteDataTable("object_to_tags").find(row => row.item_type == this.item_type && row.item_id == this.item_id && row.tag_id == optObj.id);
		if (!objToDel) {return;}
			//store delete, api delete
    const res:any=await this.apiService.delete_gen_item("object_to_tags", objToDel.id);
    if (res.success) {
      this.store.delGenItemRow("object_to_tags", objToDel.id);
    }
    else {
      let message = this.lang.getVal("delete_failed");
      this.modalService.openToast(message);
    }
	}
  keyupOtps(strToLower:string){
    let allowedTagIds=null;
    if(this.isLocationLimited){
      allowedTagIds=this.store.getSiteDataTable("object_to_tags").filter(it=>it?.item_type==="location").map(it=>it.tag_id);
    }
    const tbls = this.fuzzySearchableClasses || ["tags"];
		let opts = [];
		tbls.forEach(tbl => {
			opts = [...opts,
			...this.store.getSiteDataTable(tbl)
        .filter(it=>{
          if(allowedTagIds?.length){
            return allowedTagIds.includes(it.id)
          }
          return true;
        })
				.filter(it => it.name.toLowerCase().indexOf(strToLower) != -1)
				.map(obj => this.getOpt(obj, tbl))
			];
		});
    return opts;
  }

	getOpt(opt: any, tbl: string) {
		return { ...opt, typeClassName: tbl };
	}
  optAlreadyInChosen(optObj:any){
    return this.chosenObjs.find(ob => ob.id == optObj.id && ob.typeClassName == optObj.typeClassName);
  }
	async addOption(optObj: any) {
		this.options = [];
		this.inputStr = "";
		this.focus();

		if (this.optAlreadyInChosen(optObj)) {
      return;
		}
		this.chosenObjs = [...this.chosenObjs, { ...optObj }];
		this.outputValue();

		if (!this.saveMode) {
      return;
		}
		//store add, api add
		const objToSave = { item_id: this.item_id, item_type: this.item_type, tag_id: optObj.id };
		let message = "";
		const res:any=await this.apiService.save_gen_item("object_to_tags", objToSave);
    if (res.id) {
      this.store.addGenItemRow("object_to_tags", res);
      message = this.lang.getVal("saved_successfully");
    }
    else {
      message = this.lang.getVal("save_failed");
    }
    this.modalService.openToast(message);
	}

	async openAddModal() {
		const newRow: any = await this.modalService.openMulti("gen-table-add-item",//will receive either NULL if nothing happened or object of new inserted row!
			{
				tableName: "tags",
        fuzzyName: this.inputStr,   //fuzzyName is the actual written string in the input, will be attached to the "name" field of the new row
			});
		if (!newRow) { return };	//no new row or just cancelled
		//newRow.name
		this.addOption(newRow);



	}

}
