<div [class.input-group]="addonObject"  [class.price-width]="fieldName=='price'" [class.time-width]="fieldName | inArray:['time','time_to']"
[class.length-width]="fieldName | inArray:['length','invoice_id_patient','date_of_birth','year_of_birth','withholding_tax_percent']">

  @if(addonObject?.before){
    <span class="input-group-addon" [class.validation-checked]="errors[fieldName]===null" [class.validation-error]="errors[fieldName]" [preIcon]="addonObject.before.icon" >
      {{addonObject?.before?.text}}
    </span>
  }

  @if(type=='textarea'){
    <textarea [tabindex]="tabindex" [title]="(title_ph_lang || fieldName) | langVal" [style.height]="height || 'auto'"
    #elementEl [attr.name]="fieldName" [autofocus]="autofocus" [disabled]="disabled || isStatic" [placeholder]="placeholder || ( (title_ph_lang || fieldName) | langVal)"
    class="form-control" [ngClass]="className" [(ngModel)]="actualObj[fieldName]" (keyup)="onkeyup()" (change)="onchange()"
    [class.validation-checked]="errors[fieldName]===null" [class.validation-error]="errors[fieldName]"></textarea>
  }
  @else if(numberKeyboard){
    <input  #elementEl [tabindex]="tabindex" [title]="(title_ph_lang || fieldName) | langVal" [min]="min" [max]="max" [value]="searchStr"
    [disabled]="disabled || isStatic" [autofocus]="autofocus" [attr.name]="fieldName" [autocomplete]="autocomplete" [type]="showPassword ? 'text' : type"
    [placeholder]="placeholder || ( (title_ph_lang || fieldName) | langVal)" class="form-control" [ngClass]="className" [class.no-border-end]="addonObject?.after" [(ngModel)]="actualObj[fieldName]" (keyup)="onkeyup()" inputtype="text" inputmode="numeric" (click)="handleClick()"
    (change)="onchange()" [class.validation-checked]="errors[fieldName]===null" [class.validation-error]="errors[fieldName]"  />
  }
  @else{
    <input  #elementEl [tabindex]="tabindex" [title]="(title_ph_lang || fieldName) | langVal" [min]="min" [max]="max" [value]="searchStr"
      [disabled]="disabled || isStatic" [autofocus]="autofocus" [attr.name]="fieldName" [autocomplete]="autocomplete" [type]="showPassword ? 'text' : type"
      [placeholder]="placeholder || ( (title_ph_lang || fieldName) | langVal)" class="form-control" [ngClass]="className" [class.no-border-end]="addonObject?.after" [(ngModel)]="actualObj[fieldName]" (keyup)="onkeyup()" (click)="handleClick()"
      (change)="onchange()" [class.validation-checked]="errors[fieldName]===null" [class.validation-error]="errors[fieldName]"  />
  }



  @if(addonObject?.after){
    <span class="input-group-addon" [class.validation-checked]="errors[fieldName]===null" [class.validation-error]="errors[fieldName]" [preIcon]="addonObject.after.icon" (click)="elementEl.nativeElement.focus()">
      {{addonObject?.after?.text}}
    </span>
  }


</div>
@if(errors[fieldName]){
  <p class="error">{{errors[fieldName]}}</p>
}
